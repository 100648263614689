import { gql } from "@apollo/client";

export const QUERY_LIST_DECREES = gql`
  query PaginatedDecrees(
    $pageIndex: Int!
    $sortOrder: SortOrder
    $sortField: String
    $pageSize: Int!
    $filter: [FilterInput!]
  ) {
    paginatedDecrees(
      pageIndex: $pageIndex
      sortOrder: $sortOrder
      sortField: $sortField
      pageSize: $pageSize
      filter: $filter
    ) {
      results {
        id
        name
        createdAt
        cityId
        done
        publicUrl
        analyzed
        disabledCurbZones {
          id
          geometry
          type
          timeSpans {
            startDate
            endDate
            daysOfWeek
            daysOfMonth
            months
            timeOfDayStart
            timeOfDayEnd
          }
          calculatedSpaces
          description
          editLine
        }
      }
      totalPages
    }
  }
`;

export const UPDATE_DECREE_FILE = gql`
  mutation updateDecreeFile($updateDecreeInput: UpdateDecreeInput!) {
    updateDecreeFile(updateDecreeInput: $updateDecreeInput) {
      id
      done
    }
  }
`;
