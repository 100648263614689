import { CurbZoneMap } from "modules/curb-zone";
import { Wrapper } from "components/layout";
import { useState } from "react";
import { AppMode } from "../types";

export function CurbZonePage() {
  const [appMode, setAppMode] = useState<AppMode>("view");

  return (
    <Wrapper title="Home Page" className="mask-background">
      <div className="relative -mt-16 map-height">
        <CurbZoneMap appMode={appMode} setAppMode={setAppMode} />
      </div>
    </Wrapper>
  );
}
