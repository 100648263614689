import { Feature, LineString, Polygon } from "geojson";
import { TimeSpanInput } from "./policy.type";

export type Restriction = {
  id?: string;
  geometry: Polygon;
  timeSpans?: TimeSpanInput[];
  description?: string;
  decreeIds?: string[];
  geoPoint2d: number[];
  editLine: Feature<LineString>;
  cityId: string;
  curbZoneId?: string;
  type?: keyof typeof RestrictionType;
  width?: number;
  calculatedSpaces?: number;
  length?: number;
};

export enum RestrictionType {
  moving = "Déménagement",
  publicDomainOccupation = "Occupation du domaine publique",
  constructionWork = "En construction",
  oneTimeEvent = "Évenement ponctuel",
  disabledStreet = "Restriction de circulation",
  other = "Autres",
}

export const arrRestrictionType = Object.entries(RestrictionType).map(([key, value]) => ({ value: key, label: value }));
