import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  QUERY_CREATE_CITY,
  QUERY_PAGINATED_CITIES,
  QUERY_REMOVE_CITY,
  QUERY_UPDATE_CITY,
} from "services/web/api-cities.const";
import {
  CreateCityResponse,
  FilterParams,
  PaginatedCitiesResponse,
  RemoveCityResponse,
  UpdateCityResponse,
} from "types";

export interface PaginatedCitiesProps {
  cityName: string;
  currentPage: FilterParams;
  setCurrentPage: React.Dispatch<React.SetStateAction<FilterParams>>;
}

export const usePaginatedCitiesActions = ({ cityName, currentPage, setCurrentPage }: PaginatedCitiesProps) => {
  const client = useApolloClient();

  const variables = {
    pageIndex: currentPage.pageIndex,
    pageSize: currentPage.pageSize,
    filter: [
      {
        field: "name",
        value: cityName,
      },
    ],
  };

  const {
    data: cities,
    loading,
    refetch,
  } = useQuery<PaginatedCitiesResponse>(QUERY_PAGINATED_CITIES, {
    variables: variables,
    onCompleted: (response) => {
      client.cache.writeQuery({
        query: QUERY_PAGINATED_CITIES,
        data: {
          paginatedCities: {
            results: response.paginatedCities.results,
            totalPages: response.paginatedCities.totalPages,
          },
        },
        variables: variables,
      });
    },
  });

  const [deleteCity] = useMutation<RemoveCityResponse>(QUERY_REMOVE_CITY, {
    update: (cache) => {
      const cacheData = cache.readQuery({
        query: QUERY_PAGINATED_CITIES,
        variables: variables,
      }) as PaginatedCitiesResponse;
      const pageIndex =
        currentPage.pageIndex !== 1 && cacheData.paginatedCities.results.length === 1
          ? currentPage.pageIndex - 1
          : currentPage.pageIndex;
      refetch({ cityName, pageIndex: pageIndex });
      setCurrentPage && setCurrentPage({ ...currentPage, pageIndex });
    },
  });

  const [createNewCity] = useMutation<CreateCityResponse>(QUERY_CREATE_CITY, {
    update: () => {
      refetch({ cityName, pageIndex: currentPage.pageIndex });
    },
  });

  const [updateCity] = useMutation<UpdateCityResponse>(QUERY_UPDATE_CITY, {
    update: () => {
      refetch({ cityName, pageIndex: currentPage.pageIndex });
    },
  });

  return {
    deleteCity,
    createNewCity,
    cities,
    refetch,
    loading,
    updateCity,
  };
};
