import { Controller, useForm } from "react-hook-form";
import { MacButton } from "components";
import { Modal, ModalContent, ModalFooter } from "components/modal";
import { FieldContainer, FormInput, DropdownMultiSelect, DropdownSelect } from "components/form-control";
import { isNil } from "helper/utils";
import { DisabledPartProps } from "./disabled-part.type";
import { useMemo, useState } from "react";
import { arrRestrictionType, Restriction, RestrictionType } from "types";
import { InputNumber } from "components/form-control/input-number";
import { TimeSpanListing } from "components/time-span";
import { Feature, LineString } from "geojson";
import { calculateArea } from "helper/format-data";

export const DisabledPartModal = ({ saveDisabledPart, prefill, cancel, decreeList }: DisabledPartProps) => {
  const [selectedDecree, setSelectedDecree] = useState<string[]>(prefill?.decreeIds ?? []);
  const defaultWidth = prefill?.width ?? 2;

  const {
    setValue,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Restriction>({
    defaultValues: { ...prefill, width: defaultWidth },
    mode: "onChange",
  });

  const isEdit = !isNil(prefill.id);

  const listDecisionFile = useMemo((): any[] => {
    if (decreeList.length === 0) {
      return [];
    }
    return [...decreeList].reverse().map((item) => ({ value: item.id, label: item.name || "" }));
  }, [decreeList]);

  const dismiss = () => cancel(isEdit);
  const save = (promptedProperties: Restriction) => {
    saveDisabledPart({ ...promptedProperties });
  };

  const onChangeFile = (fileName: string[]) => {
    setValue("decreeIds", fileName);
    setSelectedDecree(fileName);
  };

  const isDisabledCurbZone = useMemo(() => {
    return !!prefill.curbZoneId;
  }, [prefill]);

  const restrictionList = useMemo(() => {
    if (isDisabledCurbZone) {
      return arrRestrictionType.filter((item) => item.label !== RestrictionType.disabledStreet);
    }
    return arrRestrictionType;
  }, [isDisabledCurbZone]);

  const headerText = useMemo(() => {
    if (isDisabledCurbZone) {
      return isEdit ? "Edit a disabled curb-zone" : "Add a new disabled curb-zone";
    }
    return isEdit ? "Edit a disabled street" : "Add a new disabled street";
  }, [isDisabledCurbZone, isEdit]);

  const getValueSpaces = (value: number) => {
    const curbZoneFeature = {
      type: "Feature",
      geometry: prefill.editLine.geometry,
      properties: { ...prefill, width: Number(value) },
    } as Feature<LineString, Restriction>;
    const { calculatedSpaces } = calculateArea(curbZoneFeature);
    return calculatedSpaces;
  };

  const calculateDisabledCurbZoneProperties = (value: number) => {
    const newValue = getValueSpaces(value);
    setValue("calculatedSpaces", newValue);
  };

  const defaultCalculatedSpaces = useMemo(() => {
    if (prefill.calculatedSpaces) {
      return prefill.calculatedSpaces;
    }
    return getValueSpaces(defaultWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefill]);

  return (
    <Modal isOpen className="my-10 max-w-lg flex items-center content-center overflow-y-visible">
      <form onSubmit={handleSubmit(save)}>
        <ModalContent
          className="w-128"
          header={
            <>
              <h3 className="text-lg leading-6 font-medium text-gray-900">{headerText}</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Disabled part information</p>
            </>
          }
        >
          <Controller
            name="timeSpans"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Timespan is required",
              },

              validate: (values) => {
                if (values) {
                  for (const item of values) {
                    if (!item.startDate || !item.endDate) {
                      return "Start date and end date must be filled";
                    }
                  }
                }
              },
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <TimeSpanListing
                  errors={errors}
                  name="timeSpans"
                  timeSpans={value}
                  setTimeSpans={onChange}
                  options={{ showSelectDate: true, showTimeOfDay: true }}
                />
              );
            }}
          />
          <FieldContainer>
            <DropdownMultiSelect
              options={listDecisionFile}
              values={selectedDecree}
              onChangeValues={onChangeFile}
              className="w-36"
              placeholder="Decrees"
            />
          </FieldContainer>
          <FieldContainer
            helpText={errors.description ? "This is required" : undefined}
            status={errors?.description ? "error" : "primary"}
          >
            <FormInput
              label="Description"
              {...register("description", { required: true })}
              status={errors.description ? "error" : "primary"}
              defaultValue={prefill?.description}
            />
          </FieldContainer>
          <FieldContainer>
            <DropdownSelect
              className="border-primary border-solid"
              value={prefill?.type}
              options={restrictionList}
              {...register("type")}
              disabled={!isDisabledCurbZone}
            />
          </FieldContainer>
          <FieldContainer>
            <InputNumber
              label="Places calculées"
              {...register("calculatedSpaces", { valueAsNumber: true })}
              status={errors.calculatedSpaces ? "error" : "primary"}
              defaultValue={defaultCalculatedSpaces}
            />
          </FieldContainer>
          <FieldContainer
            helpText={errors.width ? "This is required" : undefined}
            status={errors?.width ? "error" : "primary"}
          >
            <InputNumber
              label="Largeur"
              {...register("width", { valueAsNumber: true, required: true, min: 1 })}
              status={errors.width ? "error" : "primary"}
              defaultValue={defaultWidth}
              onChangeValue={calculateDisabledCurbZoneProperties}
            />
          </FieldContainer>
          <ModalFooter>
            <MacButton type="submit" variant="primary">
              Save
            </MacButton>
            <MacButton onClick={dismiss} variant="secondary">
              Cancel
            </MacButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};
