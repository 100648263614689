import { CSSAlignement, HeaderItem, SortDirection } from "components/data-table";
import { PresetPolicy, colors } from "constants/map.const";
import { formatArrayToRgbaColor } from "helper/utils";
import { RgbaColor } from "react-colorful";
import { ColorRgba, Policy, PolicyRuleInput, RateInput } from "types";
import {
  Activity,
  OtherTypes,
  PolicyCategory,
  Purpose,
  RateUnitPeriod,
  UnitOfTime,
  VehicleProperties,
  VehicleTypes,
} from "types/policty.enum";
import { SortOption } from "./components/PolicyTable";

export const defaultRule: PolicyRuleInput = {
  activity: Activity.parking,
  maxStayUnit: UnitOfTime.Minute,
  noReturnUnit: UnitOfTime.Minute,
  rate: [],
};

export const defaultPolicy: Policy = {
  cityId: "",
  name: "",
  priority: 1,
  rules: [defaultRule],
  timeSpans: [],
  dataSourceOperatorId: [],
  publishedDate: new Date(),
};

export const arrActivity = Object.values(Activity);

export const arrUnitOfTime = Object.values(UnitOfTime);

export const arrRateUnitPeriod = Object.values(RateUnitPeriod);

export const defaultRate: RateInput = {
  rate: 0,
  rateUnit: UnitOfTime.Minute,
};

export const arrVehicleTypes = Object.values(VehicleTypes);
export const arrVehicleProperties = Object.values(VehicleProperties);
export const arrPurpose = Object.values(Purpose);

export const arrUserClasses = [...arrVehicleTypes, ...arrVehicleProperties, ...arrPurpose];

export const defaultFillColorRgba: RgbaColor = {
  r: 70,
  g: 127,
  b: 233,
  a: 56,
};
export const defaultLineColorRgba: RgbaColor = {
  r: 70,
  g: 127,
  b: 233,
  a: 255,
};

export const defaultFillColor = Object.values(defaultFillColorRgba) as ColorRgba;
export const defaultLineColor = Object.values(defaultLineColorRgba) as ColorRgba;

export type PresetColorByPolicy = {
  title: PresetPolicy;
  rgba: RgbaColor;
};

export type PresetColor = {
  fillColors: PresetColorByPolicy[];
  lineColors: PresetColorByPolicy[];
};

export const presetPolicyColors = Object.entries(colors).reduce(
  (preValue: PresetColor, item) => {
    const newFillColor = formatArrayToRgbaColor(item[1].fillColor);
    const newLineColor = formatArrayToRgbaColor(item[1].lineColor);
    const title = item[0];

    return {
      fillColors: [...preValue.fillColors, { title, rgba: newFillColor }],
      lineColors: [...preValue.lineColors, { title, rgba: newLineColor }],
    } as PresetColor;
  },
  { fillColors: [], lineColors: [] },
);

export const arrCategory = [
  {
    label: "Autres",
    key: PolicyCategory.other,
    value: null,
  },
  {
    label: "Payant",
    key: PolicyCategory.payed,
    value: null,
  },
  {
    label: "Trottinette",
    key: PolicyCategory.scooter,
    value: null,
  },
  {
    label: "Taxi",
    key: PolicyCategory.taxi,
    value: null,
  },
  {
    label: "Gratuit",
    key: PolicyCategory.free,
    value: {
      priority: 2,
      timeOfDayStart: "00:00",
      timeOfDayEnd: "23:59",
      userClasses: [VehicleTypes.car],
      activity: Activity.parking,
    },
  },
  {
    label: "Police",
    key: PolicyCategory.police,
    value: {
      priority: 1,
      timeOfDayStart: "00:00",
      timeOfDayEnd: "23:59",
      userClasses: [OtherTypes.police],
      activity: Activity.parking,
    },
  },
  {
    label: "Livraison",
    key: PolicyCategory.delivery,
    value: {
      priority: 1,
      timeOfDayStart: "00:00",
      timeOfDayEnd: "23:59",
      maxStay: 30,
      maxStayUnit: UnitOfTime.Minute,
      userClasses: [Purpose.delivery],
      activity: Activity.parking,
    },
  },
  {
    label: "Moto",
    key: PolicyCategory.motorcycle,
    value: {
      priority: 1,
      timeOfDayStart: "00:00",
      timeOfDayEnd: "23:59",
      userClasses: [VehicleTypes.motorcycle],
      activity: Activity.parking,
    },
  },
  {
    label: "Électrique",
    key: PolicyCategory.electric,
    value: {
      priority: 1,
      timeOfDayStart: "00:00",
      timeOfDayEnd: "23:59",
      userClasses: [VehicleProperties.electric],
      activity: Activity.parking,
    },
  },
  {
    label: "GIG/GIC",
    key: PolicyCategory.disabled,
    value: {
      priority: 1,
      timeOfDayStart: "00:00",
      timeOfDayEnd: "23:59",
      userClasses: [VehicleTypes.car, VehicleProperties.HandicapAccessible],
      activity: Activity.parking,
    },
  },
  {
    label: "Vélo",
    key: PolicyCategory.bicycle,
    value: {
      priority: 1,
      timeOfDayStart: "00:00",
      timeOfDayEnd: "23:59",
      userClasses: [VehicleTypes.bicycle, VehicleTypes.bike],
      activity: Activity.parking,
    },
  },
  {
    label: "Deux Roues",
    key: PolicyCategory.twoWheels,
    value: {
      priority: 1,
      timeOfDayStart: "00:00",
      timeOfDayEnd: "23:59",
      userClasses: [VehicleTypes.scooter, OtherTypes.twoWheeledVehicles, VehicleTypes.motorbike, OtherTypes.twoWheeler],
      activity: Activity.parking,
    },
  },
  {
    label: "Transport de fond",
    key: PolicyCategory.cashTransport,
    value: {
      priority: 1,
      timeOfDayStart: "00:00",
      timeOfDayEnd: "23:59",
      userClasses: [OtherTypes.cashEscort, OtherTypes.cashTransport, OtherTypes.moneyTransporter],
      activity: Activity.parking,
    },
  },
  {
    label: "Autocar",
    key: PolicyCategory.coach,
    value: {
      priority: 1,
      timeOfDayStart: "00:00",
      timeOfDayEnd: "23:59",
      userClasses: [VehicleTypes.bus, PolicyCategory.coach, VehicleTypes.autobus],
      activity: Activity.parking,
    },
  },
  {
    label: "Pompiers",
    key: PolicyCategory.fireFigthers,
    value: {
      priority: 1,
      timeOfDayStart: "00:00",
      timeOfDayEnd: "23:59",
      userClasses: [OtherTypes.pompiers, OtherTypes.firefighter],
      activity: Activity.parking,
    },
  },
  {
    label: "Auto-partage",
    key: PolicyCategory.carpooling,
    value: null,
  },
  {
    label: "Arrêt de bus",
    key: PolicyCategory.busStop,
    value: null,
  },
  {
    label: "Dépose minute",
    key: PolicyCategory.dropOff,
    value: null,
  },
];

export const [defaultCategory] = arrCategory.filter((cat) => cat.key === PolicyCategory.other);

export const arrCategoryDropdown = arrCategory.map((cat) => cat.label);

export const defaultSort: SortOption = {
  orderBy: "publishedDate",
  direction: SortDirection.DESC,
};

export const defaultCells: HeaderItem<keyof Policy>[] = [
  { title: "Name", align: CSSAlignement.left, orderBy: "name", sortable: true, sortDirection: SortDirection.ASC },
  {
    title: "Published date",
    align: CSSAlignement.left,
    orderBy: "publishedDate",
    sortable: true,
    sortDirection: SortDirection.DESC,
  },
  {
    title: "Category",
    align: CSSAlignement.left,
    orderBy: "category",
    sortable: true,
    sortDirection: SortDirection.ASC,
  },
  {
    title: "priority",
    align: CSSAlignement.right,
    orderBy: "priority",
    sortable: true,
    sortDirection: SortDirection.ASC,
  },
  { title: "Action", align: CSSAlignement.center },
];
