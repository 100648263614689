import { getPolygonColor } from "constants/map.const";
import { CompositeLayer, GeoJsonLayer, IconLayer } from "deck.gl";
import { Feature, Point, Polygon } from "geojson";
import { ICON_MAPPING } from "modules/offstreet-parking/private-parking.const";
import { UserReport } from "types/user-report.type";

export class UserReportLayer extends CompositeLayer<UserReport[]> {
  renderLayers() {
    const { data = [] } = this.props;
    const pointData: Point[] = [];
    const polygonData: Feature<Polygon>[] = [];

    (data as UserReport[]).forEach((item) => {
      if (item?.features) {
        item.features.features.forEach((value) => {
          if (value.geometry.type === "Point") {
            pointData.push(value.geometry);
          } else {
            polygonData.push(value as Feature<Polygon>);
          }
        });
      }
    });

    return [
      new IconLayer(
        this.getSubLayerProps({
          id: "point-layer",
          data: pointData,
          pickable: true,
          iconAtlas: "images/maker.png",
          iconMapping: ICON_MAPPING,
          getIcon: () => "marker",
          sizeScale: 8,
          getPosition: (d: any) => d.coordinates,
          getSize: 3,
        }),
      ),
      new GeoJsonLayer(
        this.getSubLayerProps({
          id: "polygon-layer",
          data: polygonData,
          stroked: true,
          getFillColor: (feature: any) => getPolygonColor(feature, "fillColor"),
          getLineColor: (feature: any) => getPolygonColor(feature, "lineColor"),
          lineWidthMaxPixels: 1,
        }),
      ),
    ];
  }
}

UserReportLayer.layerName = "UserReportLayer";
