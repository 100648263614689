import { TileLayer } from "@deck.gl/geo-layers";
import { BitmapLayer } from "@deck.gl/layers";
import DeckGL from "@deck.gl/react";
import { mapboxStyle, ModeViewType } from "constants/map.const";
import { MAPBOX_ACCESS_TOKEN } from "constants/mapbox.const";
import { useAddControlMapbox } from "hooks/use-add-control-mapbox";
import { createMarkerLayer } from "helper/map-utils";
import { useMemo } from "react";
import { Map, ViewState } from "react-map-gl";
import { AppModeAttribute, ViewportPlaceType } from "types";
import { interactionStateType, MapType, mapUrlByType, TooltipType } from "types/map.type";
import { CurbZoneTooltip } from "../curb-zone-tooltip";
import { CurbZone } from "types/curb-zone.type";
import { cursorCell } from "./map-style.const";

export interface MapBoxProps {
  markerPosition: ViewportPlaceType | null;
  mapType: MapType;
  viewport: ViewState;
  layers: any[];
  handleSelectLayer: (event: any) => void;
  handleDragEnd: (event: any) => void;
  handleZooming: (input: interactionStateType) => void;
  onViewStateChange: ({ viewState }: any) => void;
  hoverInfo?: TooltipType<CurbZone>;
  modeView: ModeViewType;
  mapRef: any;
  appMode: keyof AppModeAttribute<any>;
  isSpaceBarPressed?: boolean;
}

export const MapStyle = ({
  mapType,
  markerPosition,
  viewport,
  layers,
  handleSelectLayer,
  handleDragEnd,
  handleZooming,
  onViewStateChange,
  hoverInfo,
  modeView,
  mapRef,
  appMode,
  isSpaceBarPressed = false,
}: MapBoxProps) => {
  const mapStyle = mapboxStyle[modeView];
  const { mapRefCallback } = useAddControlMapbox();
  const markerLayer = useMemo(() => {
    if (markerPosition) {
      return createMarkerLayer({ coordinates: [markerPosition.longitude, markerPosition.latitude] });
    }
    return null;
  }, [markerPosition]);

  const mapLayers = useMemo(() => {
    if (mapType === MapType.MAPBOX) {
      if (markerLayer) {
        return [...layers, markerLayer];
      }
      return layers;
    }
    const tileLayer = new TileLayer({
      id: "sat-other-map",
      data: mapUrlByType[mapType],

      minZoom: 0,
      maxZoom: 19,
      tileSize: 256,

      renderSubLayers: ({ tile, data, ...props }: any) => {
        const {
          bbox: { west, south, east, north },
        } = tile;

        return new BitmapLayer(props, {
          image: data,
          bounds: [west, south, east, north],
        });
      },
    });
    if (markerLayer) {
      return [tileLayer, ...layers, markerLayer];
    }
    return [tileLayer, ...layers];
  }, [layers, mapType, markerLayer]);

  const getCursor = () => {
    if (cursorCell.includes(appMode)) {
      return () => "cell";
    }
    if (["multiEdit", "multiCreateDisabledCurbZone"].includes(appMode)) {
      if (isSpaceBarPressed) {
        return ({ isDragging }: { isDragging: boolean }) => (isDragging ? "grabbing" : "grab");
      }
      return () => "cell";
    }
    return ({ isDragging }: { isDragging: boolean }) => (isDragging ? "grabbing" : "grab");
  };

  return (
    <DeckGL
      initialViewState={viewport}
      controller={{
        touchZoom: true,
        touchRotate: true,
      }}
      getCursor={getCursor()}
      layers={mapLayers}
      pickingRadius={12}
      onClick={handleSelectLayer}
      onDragEnd={handleDragEnd}
      onInteractionStateChange={handleZooming}
      ref={mapRef}
      onViewStateChange={onViewStateChange}
    >
      <Map
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        mapStyle={mapStyle}
        style={{ width: "100%", height: "100%" }}
        ref={mapRefCallback}
      />
      {appMode === "view" && hoverInfo?.properties && (
        <CurbZoneTooltip left={hoverInfo.x} top={hoverInfo.y} curbZone={hoverInfo.properties} />
      )}
    </DeckGL>
  );
};
