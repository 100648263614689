import {
  DataTable,
  HeaderItem,
  SortDirection,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderProps,
  TableRow,
  TableSkeleton,
} from "components/data-table";
import { Checkbox } from "components/form-control";
import { ComponentType, useContext, useMemo, useState } from "react";
import { Decree, PaginatedDecrees, PaginatedDecreeArgs, UpdateDecreeInput, DecreeFilterList } from "types/decree.type";
import { useUpdateDecree } from "../hooks/use-query-decree-file";
import { formatDate } from "helper/date-time";
import RobotEmoji from "assets/robot-emoji-icon.png";
import * as React from "react";
import { PdfIcon } from "components/icons";
import { DecreeModal } from "./decree-modal";
import { isEmpty } from "helper/utils";
import { Pagination } from "components/data-table/Pagination";
import { DecreeAnalyzed, DecreeFilter, DecreeStatus, defaultDecreeCells, defaultDecreeFilter } from "./decree.const";
import { CityContext } from "contexts/city-context-provider";
import { FilterDecree } from "./filter-decree";

interface DisabledParkingListingProps {
  decreeList: PaginatedDecrees;
  selectedDecree: Decree | null;
  onSelectDecree: (input: Decree) => void;
  isLoading: boolean;
  decreesParams: PaginatedDecreeArgs;
  setDecreeParams: React.Dispatch<React.SetStateAction<PaginatedDecreeArgs>>;
}

const TableHeaderWithDecree: ComponentType<TableHeaderProps<keyof Decree>> = TableHeader;

export const DecreeListing = ({
  decreeList,
  selectedDecree,
  onSelectDecree,
  isLoading,
  decreesParams,
  setDecreeParams,
}: DisabledParkingListingProps) => {
  const [viewFile, setViewFile] = useState<Decree | null>(null);
  const [headerCells, setHeaderCells] = useState<HeaderItem<keyof Decree>[]>(defaultDecreeCells);
  const [filter, setFilter] = useState<DecreeFilter>(defaultDecreeFilter);

  const { currentCity } = useContext(CityContext);
  const { updateDecreeFile } = useUpdateDecree(decreesParams);

  const decreeListSorted = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return decreeList.results;
  }, [isLoading, decreeList]);

  if (!currentCity) {
    return <EmptyData />;
  }

  const changeStatus = (input: Decree) => {
    const newDecree: UpdateDecreeInput = {
      id: input.id,
      name: input.name,
      title: input.title,
      done: !input.done,
    };
    const variables = {
      updateDecreeInput: newDecree,
    };
    updateDecreeFile({ variables });
  };

  const handleSort = (orderBy: keyof Decree, direction: SortDirection) => {
    const newCells = headerCells.map((cell) => {
      if (cell.orderBy === orderBy) {
        return {
          ...cell,
          sortDirection: direction,
        };
      }
      return cell;
    });
    setDecreeParams((prevState) => ({ ...prevState, sortField: orderBy, sortOrder: direction }));
    setHeaderCells(newCells);
  };

  const onChangePage = (page: number) => {
    setDecreeParams((prevState) => ({ ...prevState, pageIndex: page }));
  };

  const onChangeFilter = (input: DecreeFilter) => {
    const { searchString, analyzed, status } = input;

    setFilter(input);

    const filter = [
      {
        field: "cityId",
        value: currentCity?.id,
      },
    ] as DecreeFilterList;

    if (status.length === 1) {
      filter.push({
        field: "done",
        value: String(DecreeStatus.DONE === status[0]),
      });
    }

    if (analyzed.length === 1) {
      filter.push({
        field: "analyzed",
        value: String(DecreeAnalyzed.ANALYSED === analyzed[0]),
      });
    }

    if (searchString.length > 0) {
      filter.push({
        field: "name",
        value: searchString,
      });
    }

    setDecreeParams((prevState) => ({
      ...prevState,
      filter,
      pageIndex: 1,
    }));
  };

  return (
    <>
      <div className="flex flex-col w-full gap-y-2 bg-white p-7">
        <div className="flex justify-between mb-11">
          <span className="font-bold text-lg">Arrêtés municipaux</span>
          <FilterDecree filter={filter} onChange={onChangeFilter} />
        </div>
        <div className="w-full h-full">
          <DataTable className="p-0 h-fit max-h-full rounded-none block shadow-none bg-white">
            <TableHeaderWithDecree
              className="text-black bg-white text-sm border-b-2 border-secondary"
              columnName={headerCells}
              onSort={handleSort}
            />
            <TableBody className="min-h-fit  max-h-full">
              {isLoading ? (
                <TableSkeleton />
              ) : (
                <>
                  {isEmpty(decreeListSorted) ? (
                    <GenNoRecord
                      colSpan={headerCells.length}
                      message={isLoading ? "Loading..." : "No results found."}
                    />
                  ) : (
                    decreeListSorted.map((item, idx) => (
                      <TableRow
                        className={`hover:none ${
                          selectedDecree?.id === item.id ? "primary-active text-white" : "bg-white"
                        }`}
                        key={`disabled-list-${idx}`}
                      >
                        <TableCell className="text-sm px-6 py-3 cursor-pointer" onClick={() => onSelectDecree(item)}>
                          {item.name}
                        </TableCell>
                        <TableCell className="text-sm px-6 py-3 justify-end w-44">
                          {formatDate(item.createdAt)}
                        </TableCell>
                        <TableCell className="text-sm px-6 py-3 flex justify-center w-20">
                          {item.analyzed ? <img className="h-4 w-4" src={RobotEmoji} alt="macaron" /> : ""}
                        </TableCell>
                        <TableCell className="text-sm px-6 py-3 w-16 text-center">
                          <div className="flex justify-center">
                            <Checkbox
                              wrapperClass="w-4 h-4"
                              variant={selectedDecree?.id === item.id ? "secondary" : "primary"}
                              checked={item.done}
                              onChange={() => changeStatus(item)}
                            />
                          </div>
                        </TableCell>
                        <TableCell className="text-sm px-6 py-3 flex justify-center cursor-pointer">
                          {item.publicUrl && <PdfIcon className="h-5 w-5" onClick={() => setViewFile(item)} />}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </>
              )}
            </TableBody>
          </DataTable>
        </div>
        <div className="mb-2 flex flex-col items-end">
          <Pagination
            onChangePage={onChangePage}
            currentPage={decreesParams.pageIndex}
            perPage={decreesParams.pageSize}
            totalPage={decreeList.totalPages}
          />
        </div>
      </div>
      {viewFile && <DecreeModal decree={viewFile} closeModal={() => setViewFile(null)} />}
    </>
  );
};

interface GenNoRecordProps {
  colSpan?: number;
  message?: string;
}

export const GenNoRecord = ({ colSpan = 5, message = "No results found" }: GenNoRecordProps) => {
  return (
    <tr className="bg-default">
      <td className="px-6 py-4 whitespace-nowrap bg-white	 text-primary" colSpan={colSpan}>
        <div className="flex items-center justify-center">{message}</div>
      </td>
    </tr>
  );
};

export const EmptyData = () => (
  <div className="flex flex-col w-full gap-y-2 bg-white p-7">
    <div className="flex justify-between mb-11">
      <span className="font-bold text-lg">Arrêtés municipaux</span>
    </div>
    <div className="w-full bottom-2 pb-8 h-full">
      <DataTable className="p-0 h-fit max-h-full rounded-none block shadow-none bg-white">
        <TableHeader
          className="text-black bg-white text-sm border-b-2 border-secondary"
          columnName={defaultDecreeCells}
        />
        <TableBody className="min-h-fit  max-h-full">
          <GenNoRecord message="Please select a city" colSpan={5} />
        </TableBody>
      </DataTable>
    </div>
  </div>
);
