import clsx from "clsx";
import { ComponentPropsWithoutRef, useMemo } from "react";
import { isEmpty, isPrimitive, Primitive } from "helper/utils";
import { borderStatus, FieldStatus } from "./field.type";
import { forwardRef, useEffect, useState } from "react";
import { hasClassName } from "helper/check-class";
import { Label } from "../label";

export type OptionType = {
  value: Primitive;
  label: string;
};
export type DropdownSelectProps<T = string> = ComponentPropsWithoutRef<"select"> & {
  status?: FieldStatus;
  value?: T | undefined | null;
  onChangeValue?: (value: Primitive, label?: string) => void;
  options: T extends Primitive ? T[] : OptionType[];
  className?: string;
  label?: string;
};

export const DropdownSelect = forwardRef<HTMLSelectElement, DropdownSelectProps<Primitive | OptionType>>(
  function DropdownSelect(
    { status = "default", value, options, className, label, onChangeValue, ...selectProps },
    ref,
  ) {
    const { name, disabled } = selectProps;
    const optionData = useMemo(
      () =>
        (options as any[]).map(
          (opt) =>
            (isPrimitive(opt)
              ? {
                  value: opt,
                  label: String(opt),
                }
              : opt) as OptionType,
        ),
      [options],
    );
    const [isFocused, setIsFocused] = useState<boolean>(true);
    useEffect(() => {
      value && isEmpty(value) && setIsFocused(true);
    }, [value]);
    const getLabelClass = clsx(isFocused ? "form-label-focus" : "form-label-nonfocus", disabled && "text-disabled");

    return (
      <div className="relative">
        <Label htmlFor={name ?? ""} className={getLabelClass}>
          {label}
        </Label>
        <select
          className={clsx(
            !hasClassName(className, "border-") && status && borderStatus[status],
            !disabled && "cursor-pointer",
            "block text-left transition ease-in-out duration-150 w-full form-input focus-visible:outline-none",
            className,
          )}
          onChange={(item) => {
            if (item && onChangeValue) {
              const { value } = item.target;
              onChangeValue(value);
            }
          }}
          {...selectProps}
          ref={ref}
        >
          {optionData.map((opt, index) => (
            <option
              value={opt.value as string}
              key={`dropdown-select-${index}`}
              className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
            >
              {opt.label}
            </option>
          ))}
        </select>
      </div>
    );
  },
);
