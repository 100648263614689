import { useState } from "react";
import { useForm } from "react-hook-form";
import { MacButton } from "components";
import { Modal, ModalContent, ModalFooter } from "components";
import { CurbZoneMultiplePromptProps } from "./curb-zone.type";
import { PolicyView } from "./policy-view";
import { CurbZone } from "types/curb-zone.type";

export const CurbZoneMultiplePrompt = ({
  numberOfParking,
  cancel,
  registerCurbPolicies,
}: CurbZoneMultiplePromptProps) => {
  const { handleSubmit } = useForm<CurbZone>();
  const [curbPolicies, setCurbPolicies] = useState<string[]>([]);

  const saveParking = () => {
    registerCurbPolicies(curbPolicies);
  };

  return (
    <Modal isOpen className="my-0 h-full max-w-lg flex items-center content-center">
      <ModalContent
        className="pb-0 rounded-2xl"
        header={
          <>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Edit Curb Zone</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Currently selecting {numberOfParking} Curb Zones</p>
          </>
        }
      >
        <form onSubmit={handleSubmit(saveParking)}>
          <PolicyView curbPolicies={curbPolicies} setCurbPolicies={setCurbPolicies} />

          <ModalFooter>
            <MacButton type="submit" variant="primary">
              Save
            </MacButton>
            <MacButton onClick={cancel} variant="secondary">
              Cancel
            </MacButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
