import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { CityContext } from "contexts/city-context-provider";
import { Feature, FeatureCollection, Polygon } from "geojson";
import { editableGeoJsonLayerModes, filterParkingLotIndex, parkingLotMode } from "constants/map.const";
import { MapViewProps } from "modules/curb-zone/curb-zone.type";
import { EditableGeoJsonLayer, FeatureOf } from "@deck.gl-community/editable-layers";
import { useQuery } from "@apollo/client";
import { QUERY_CURB_ZONES } from "services/web/api-curb-zone.const";
import { CurbZonesResponse } from "services/web/api-curb-zone.type";
import { useQueryParkingLot } from "./use-query-parking-lot";
import { CreateParkingLotInput, ParkingLot, UpdateParkingLotInput } from "services/web/api-parking-lot.type";
import { GeoJsonLayer } from "deck.gl";
import { findObject } from "helper/array";
import { ParkingLotProperties } from "../components/parking-lot/modify-parking-lot-prompt";

type UseCityParams = Pick<MapViewProps, "appMode">;
export const useParkingLot = ({ appMode }: UseCityParams) => {
  const { currentCity } = useContext(CityContext);
  const [openParkingLotModal, setOpenParkingLotModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const isDrawing = useRef(false);
  const currentEditParkingLotLayer = useRef<Feature<Polygon, ParkingLot>>();
  const [parkingLotDetail, setParkingLotDetail] = useState<ParkingLot>();
  const [parkingLotEditionData, setParkingLotEditionData] = useState<FeatureCollection<Polygon, ParkingLot>>({
    type: "FeatureCollection",
    features: [],
  });

  const showParkingLotDetail = useMemo(
    () => appMode === "editParkingLot" && parkingLotDetail && Object.keys(parkingLotDetail).length > 0,
    [appMode, parkingLotDetail],
  );

  const parkingLotIndexes = useMemo(
    () => filterParkingLotIndex(parkingLotEditionData, parkingLotDetail),
    [parkingLotDetail, parkingLotEditionData],
  );

  const boundingBox = useMemo(() => {
    if (appMode === "editParkingLot") {
      return parkingLotEditionData.features[
        findObject(parkingLotEditionData.features, "id", parkingLotDetail?.id)
      ]?.geometry?.coordinates.flat(1);
    }
    return parkingLotEditionData.features[parkingLotEditionData.features.length - 1]?.geometry?.coordinates.flat(1);
  }, [parkingLotEditionData, appMode, parkingLotDetail]);

  const {
    data: filteredCurbZones,
    loading: filteredCurbZoneLoading,
    refetch: refetchFilteredCurbZones,
    error: filteredCurbZoneError,
  } = useQuery<CurbZonesResponse>(QUERY_CURB_ZONES, {
    variables: {
      filterInput: {
        cityId: currentCity?.id,
        boundingbox: boundingBox,
      },
    },
    skip: !boundingBox,
  });

  const { parkingLots, loading: parkingLotLoading, handleAddParkingLot, handleUpdateParkingLot } = useQueryParkingLot();

  useEffect(() => {
    if (filteredCurbZoneError) {
      resetEditParkingLotState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCurbZoneError]);

  useEffect(() => {
    if (!isDrawing.current || filteredCurbZoneLoading || appMode !== "addParkingLot") {
      return;
    }
    if (!filteredCurbZones?.curbZones.length) {
      setShowWarning(true);
    } else {
      setOpenParkingLotModal(true);
    }
  }, [filteredCurbZones, filteredCurbZoneLoading, appMode]);

  useEffect(() => {
    if (!currentCity || parkingLotLoading) {
      return;
    }
    const listPolygon: Feature<Polygon, ParkingLot>[] = [];
    parkingLots.forEach((parkingLot) => {
      const newParkingEdition = {
        type: "Feature",
        id: parkingLot.id,
        geometry: parkingLot.geometry,
        properties: {
          name: parkingLot.name,
          address: parkingLot.address,
          note: parkingLot.note,
          curbZoneIds: parkingLot.curbZoneIds,
          cityId: parkingLot.cityId,
        },
      } as Feature<Polygon, ParkingLot>;
      listPolygon.push(newParkingEdition);
    });
    setParkingLotEditionData({
      type: "FeatureCollection",
      features: listPolygon,
    });
  }, [parkingLots, parkingLotLoading, currentCity]);

  const onEditParkingLotLayer = ({ updatedData, editType }: any) => {
    setParkingLotEditionData(updatedData);
    isDrawing.current = true;
    if (appMode === "editParkingLot") {
      if (editType === "finishMovePosition") {
        saveParkingLotProperties();
      }
    }
  };

  const onClickParkingLotLayer = (info: any) => {
    if (info.object && appMode === "editParkingLot") {
      setParkingLotDetail({
        id: info.object.id,
        geometry: info.object.geometry,
        ...info.object.properties,
      });
    } else {
      setParkingLotDetail(undefined);
    }
  };

  const handleSelectParkingLot = async (info: any) => {
    await refetchFilteredCurbZones();

    if (info.layer?.id !== "edit-parking-lot-layer" || !info.object || !info.object?.id) {
      setParkingLotDetail(undefined);
      currentEditParkingLotLayer.current = Object.assign({});
      return;
    }
    currentEditParkingLotLayer.current = info.object;
  };

  const resetEditParkingLotState = () => {
    if (appMode === "addParkingLot") {
      setParkingLotEditionData((prev) => ({
        type: "FeatureCollection",
        features: prev.features.filter((feature) => feature.id),
      }));
    } else {
      const originalParkingLotPolygon = parkingLotEditionData.features.filter(
        (polygonData) => polygonData.id !== currentEditParkingLotLayer.current?.id,
      );
      const resetParkingLotPolygonData = [
        ...originalParkingLotPolygon,
        { ...currentEditParkingLotLayer.current },
      ] as Feature<Polygon, ParkingLot>[];
      setParkingLotEditionData({
        type: "FeatureCollection",
        features: resetParkingLotPolygonData,
      });
    }
    isDrawing.current = false;
    setShowWarning(false);
    setParkingLotDetail(undefined);
    setOpenParkingLotModal(false);
    currentEditParkingLotLayer.current = Object.assign({});
  };

  const checkCurbZoneExistBeforeUpdate = () => {
    if (appMode !== "editParkingLot") {
      return;
    }
    if (!filteredCurbZones?.curbZones.length) {
      setShowWarning(true);
    } else {
      setOpenParkingLotModal(true);
    }
  };

  const saveParkingLotProperties = async (promptedProperties?: ParkingLotProperties) => {
    if (appMode === "addParkingLot") {
      const input = {
        parkingLotInput: {
          ...promptedProperties,
          curbZoneIds,
          geometry: geometryParkingLotFeature,
          cityId: currentCity?.id,
        },
      } as CreateParkingLotInput;
      await handleAddParkingLot(input);
    } else {
      const input = {
        parkingLotInput: {
          ...(promptedProperties || parkingLotDetail),
          id: parkingLotDetail?.id,
          curbZoneIds,
          cityId: currentCity?.id,
          geometry: geometryParkingLotFeature,
        },
      } as UpdateParkingLotInput;
      handleUpdateParkingLot(input);
    }
  };

  const curbZoneIds = useMemo(() => {
    if (!filteredCurbZones?.curbZones.length) {
      return [];
    }
    return filteredCurbZones.curbZones.map((curbZone) => curbZone.curbZoneId);
  }, [filteredCurbZones]);

  const geometryParkingLotFeature = useMemo(() => {
    if (!parkingLotEditionData?.features.length) {
      return [];
    }
    if (appMode === "editParkingLot") {
      const fParkingLotFeature = findObject(parkingLotEditionData.features, "id", parkingLotDetail?.id);
      if (fParkingLotFeature < 0) {
        return [];
      }
      return parkingLotEditionData.features[fParkingLotFeature].geometry;
    }
    return parkingLotEditionData.features[parkingLotEditionData.features.length - 1].geometry;
  }, [parkingLotEditionData, appMode, parkingLotDetail?.id]);

  const editableGeoJsonLayerMode = useMemo(() => editableGeoJsonLayerModes[appMode], [appMode]);

  const isVisibleParkinglot = useMemo(() => {
    return parkingLotMode.includes(appMode);
  }, [appMode]);

  const parkingLotLayer = new GeoJsonLayer({
    id: "parking-lot-layer",
    data: parkingLotEditionData,
    lineWidthMinPixels: 1,
    lineWidthMaxPixels: 1,
    getFillColor: [0, 0, 0, 0],
    getLineColor: [255, 0, 0],
    visible: !isVisibleParkinglot,
  });

  const editableParkingLotLayer = new EditableGeoJsonLayer({
    id: "edit-parking-lot-layer",
    data: { type: parkingLotEditionData.type, features: parkingLotEditionData.features as FeatureOf<Polygon>[] },
    mode: editableGeoJsonLayerMode,
    selectedFeatureIndexes: parkingLotIndexes,
    lineWidthMinPixels: 1,
    lineWidthMaxPixels: 1,
    autoHighlight: true,
    getFillColor: [0, 0, 0, 0],
    getLineColor: [255, 0, 0, 255],
    onEdit: onEditParkingLotLayer,
    onClick: onClickParkingLotLayer,
    visible: isVisibleParkinglot,
  });

  const parkingLotLayers = [editableParkingLotLayer, parkingLotLayer];

  return {
    parkingLotLayers,
    currentCity,
    parkingLotEditionData,
    openParkingLotModal,
    showParkingLotDetail,
    parkingLotDetail,
    setParkingLotDetail,
    setOpenParkingLotModal,
    showWarning,
    setShowWarning,
    filteredCurbZones,
    resetEditParkingLotState,
    handleSelectParkingLot,
    saveParkingLotProperties,
    checkCurbZoneExistBeforeUpdate,
  };
};
