import { SetStateAction, Dispatch } from "react";
import { DataTable, TableBody, TableCell, TableRow } from "components/data-table";
import { Confirm, MacButton } from "components";
import { Feature, Polygon } from "geojson";
import { Restriction } from "types";
import { CloseIcon } from "components/icons";
import useToggle from "hooks/use-toggle";
import { RemoveRestrictionParam } from "services/web/api-restriction.type";

type SelectedMultipleCurbZoneDetailProps = {
  disabledCurbZones: Feature<Polygon, Restriction>[];
  resetState: () => void;
  openCurbZoneMultiModal: Dispatch<SetStateAction<boolean>>;
  handleRemoveMultiDisabledCurbZone: (input: RemoveRestrictionParam[]) => void;
};

export const SelectedMultipleDisabledCurbZoneDetail = ({
  disabledCurbZones,
  resetState,
  openCurbZoneMultiModal,
  handleRemoveMultiDisabledCurbZone,
}: SelectedMultipleCurbZoneDetailProps) => {
  const [openConfirm, setOpenConfirm] = useToggle(false);
  const removeMultiDisabledCurbZone = () => {
    const disabledCurbZonesIds = disabledCurbZones.map(({ id }) => ({
      id: id as string,
    }));
    handleRemoveMultiDisabledCurbZone(disabledCurbZonesIds);
    resetState();
  };

  return (
    <>
      <div className="transition-opacity absolute h-auto top-36 bg-primary p-3 right-6 mt-2 mb-3 rounded-md shadow-lg z-10 w-64">
        <div className="flex justify-end h-6 cursor-pointer">
          <CloseIcon className="text-white w-4 h-4" onClick={resetState} />
        </div>
        <DataTable className="p-0 shadow-none rounded-none">
          <TableBody>
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary pb-4">Currently selecting</TableCell>
              <TableCell className="text-table-data text-secondary pb-4 flex justify-end">
                {disabledCurbZones?.length} Disabled Curb Zones
              </TableCell>
            </TableRow>
          </TableBody>
        </DataTable>
        <div className="flex justify-between">
          <MacButton onClick={setOpenConfirm} variant="error">
            Delete
          </MacButton>
          <MacButton onClick={() => openCurbZoneMultiModal(true)} variant="secondary">
            Edit
          </MacButton>
        </div>
      </div>
      {openConfirm && (
        <Confirm
          title="Delete disabled curb zones"
          content="Are you sure that you want to delete the disabled curb zones?"
          onCancel={setOpenConfirm}
          onOk={removeMultiDisabledCurbZone}
          butOkName="Delete"
          type="error"
        />
      )}
    </>
  );
};
