import { AppMode, AppModeAttribute, Restriction } from "types";
import { Feature, LineString, FeatureCollection, Polygon } from "geojson";
import {
  DrawLineStringMode,
  DrawPolygonMode,
  GeoJsonEditMode,
  ModifyMode,
  ViewMode,
} from "@deck.gl-community/editable-layers";

import { isEmpty, isNil } from "helper/utils";
import { findObject } from "helper/array";
import { ViewState } from "react-map-gl";
import { CurbZone } from "../types/curb-zone.type";
import { CurbArea } from "types/curb-area.type";
import { ParkingLot } from "services/web/api-parking-lot.type";

export const INITIAL_VIEW_STATE: ViewState = {
  latitude: 48.8967,
  longitude: 2.2567,
  zoom: 14,
  pitch: 0,
  bearing: 0,
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
};

export const mapboxStyle = {
  normal: "mapbox://styles/abdoumacaron/clgnp0ij500dw01qu0iey2r27",
  satellite: "mapbox://styles/mapbox/satellite-streets-v12",
};

export type ModeViewType = "normal" | "satellite";

export type PresetPolicy =
  | "PAYANT"
  | "GIG/GIC"
  | "GRATUIT"
  | "AUTOCAR"
  | "TROTTINETTE"
  | "LIVRAISON PERMANENTE"
  | "LIVRAISON PÉRIODIQUE"
  | "VÉLIB"
  | "ÉLECTRIQUE"
  | "TRANSPORT DE FOND"
  | "MOTOS"
  | "TAXI"
  | "VÉLO"
  | "POLICE"
  | "DÉPOSE MINUTE"
  | "DEUX ROUES"
  | "AUTRES";

interface IColors {
  [key: string]: {
    fillColor: [number, number, number, number];
    lineColor: [number, number, number, number];
    dashedBorder: boolean;
  };
}

export const colors: IColors = {
  PAYANT: {
    fillColor: [70, 127, 233, 56],
    lineColor: [70, 127, 233, 255],
    dashedBorder: true,
  },
  "GIG/GIC": {
    fillColor: [102, 184, 233, 56],
    lineColor: [102, 184, 233, 255],
    dashedBorder: true,
  },
  GRATUIT: {
    fillColor: [70, 127, 233, 56],
    lineColor: [70, 127, 233, 255],
    dashedBorder: true,
  },
  AUTOCAR: {
    fillColor: [45, 0, 53, 56],
    lineColor: [45, 0, 53, 255],
    dashedBorder: true,
  },
  TROTTINETTE: {
    fillColor: [106, 170, 175, 56],
    lineColor: [106, 170, 175, 255],
    dashedBorder: true,
  },
  "LIVRAISON PERMANENTE": {
    fillColor: [255, 227, 36, 56],
    lineColor: [255, 227, 36, 100],
    dashedBorder: true,
  },
  "LIVRAISON PÉRIODIQUE": {
    fillColor: [255, 227, 36, 56],
    lineColor: [255, 227, 36, 255],
    dashedBorder: true,
  },
  VÉLIB: {
    fillColor: [106, 170, 175, 56],
    lineColor: [106, 170, 175, 255],
    dashedBorder: true,
  },
  ÉLECTRIQUE: {
    fillColor: [40, 208, 173, 56],
    lineColor: [40, 208, 173, 255],
    dashedBorder: true,
  },
  "TRANSPORT DE FOND": {
    fillColor: [243, 0, 87, 56],
    lineColor: [243, 0, 87, 255],
    dashedBorder: true,
  },
  MOTOS: {
    fillColor: [255, 118, 126, 56],
    lineColor: [255, 118, 126, 255],
    dashedBorder: true,
  },
  TAXI: {
    fillColor: [131, 59, 230, 56],
    lineColor: [131, 59, 230, 255],
    dashedBorder: true,
  },
  VÉLO: {
    fillColor: [106, 170, 175, 56],
    lineColor: [106, 170, 175, 255],
    dashedBorder: true,
  },
  POLICE: {
    fillColor: [11, 50, 191, 56],
    lineColor: [11, 50, 191, 255],
    dashedBorder: true,
  },
  "DÉPOSE MINUTE": {
    fillColor: [231, 145, 0, 56],
    lineColor: [231, 145, 0, 255],
    dashedBorder: true,
  },
  "DEUX ROUES": {
    fillColor: [0, 94, 126, 56],
    lineColor: [0, 94, 126, 255],
    dashedBorder: true,
  },
  AUTRES: {
    fillColor: [37, 41, 45, 56],
    lineColor: [37, 41, 45, 127],
    dashedBorder: true,
  },
};

export const getPolygonColor = (feature: any, type: "fillColor" | "lineColor"): [number, number, number, number] => {
  if (feature.properties.curbPolicies?.length) {
    return feature.properties.curbPolicies?.[0].color?.[type] || colors.AUTRES[type];
  }

  return colors.AUTRES[type];
};

export const toLineStringFeature = <T extends CurbZone | Restriction>(pa: T): Feature<LineString, T> => {
  const { editLine } = pa;
  return {
    ...editLine,
    properties: pa,
    id: pa.id,
  };
};

/** Different GeoJsonEditModes depending on app mode */
export const editableGeoJsonLayerModes: AppModeAttribute<new () => GeoJsonEditMode> = {
  view: ViewMode,
  add: DrawLineStringMode,
  edit: ModifyMode,
  multiEdit: ModifyMode,
  multiCreateDisabledCurbZone: ModifyMode,
  editDisabledCurbZone: ModifyMode,
  addDisabledCurbZone: DrawLineStringMode,
  addDisabledStreet: DrawLineStringMode,
  editDisabledStreet: ModifyMode,
  addCurbArea: DrawPolygonMode,
  editCurbArea: ModifyMode,
  addParkingLot: DrawPolygonMode,
  editParkingLot: ModifyMode,
};

export const filterParkingIndex = (
  parkingEditionData: FeatureCollection<LineString>,
  selectedParkings: Feature<Polygon>[],
) => {
  if (isEmpty(selectedParkings) || isNil(selectedParkings[0])) return [];
  const indexes: number[] = [];
  selectedParkings.forEach((item) => {
    if (!isNil(item) && item.id) {
      const fIndex = findObject(parkingEditionData.features, "id", item.id);
      if (fIndex > -1) {
        indexes.push(fIndex);
      }
    }
  });
  return indexes;
};

export const filterCurbAreaIndex = (curbAreaEditionData: FeatureCollection, selectedCurbArea?: CurbArea) => {
  if (isEmpty(selectedCurbArea) || isNil(selectedCurbArea)) return [];
  const indexes: number[] = [];
  if (selectedCurbArea.id) {
    const fIndex = findObject(curbAreaEditionData.features, "id", selectedCurbArea.id);
    if (fIndex > -1) {
      indexes.push(fIndex);
    }
  }
  return indexes;
};

export const filterParkingLotIndex = (parkingLotEditionData: FeatureCollection, selectedParking?: ParkingLot) => {
  if (isEmpty(selectedParking) || isNil(selectedParking)) return [];
  const indexes: number[] = [];
  if (selectedParking.id) {
    const fIndex = findObject(parkingLotEditionData.features, "id", selectedParking.id);
    if (fIndex > -1) {
      indexes.push(fIndex);
    }
  }
  return indexes;
};
export const filterLineStringFromPoint = (infos: any) => {
  if (infos && infos.length > 0) {
    const parkings = infos.reduce((filteredArray: any[], info: any) => {
      if (info && info.object.id && info.object.geometry.type === "LineString") {
        filteredArray.push(info.object);
      }
      return filteredArray;
    }, []);
    return parkings;
  }
  return [];
};

/**
 * Only show the editLine in these modes
 * **/
export const parkingLotMode: AppMode[] = ["addParkingLot", "editParkingLot"];
export const curbAreaMode: AppMode[] = ["addCurbArea", "editCurbArea"];
export const disabledCurbZoneMode: AppMode[] = [
  "view",
  "addDisabledCurbZone",
  "editDisabledCurbZone",
  "multiCreateDisabledCurbZone",
  "multiEdit",
];
export const disabledStreetMode: AppMode[] = ["addDisabledStreet", "editDisabledStreet"];
