import * as React from "react";
import { TextInput } from "./form-control";
import { HintText } from "./hint-text";
import { FieldStatus, textStatus } from "./form-control/field.type";
import { Label } from "./label";
import clsx from "clsx";
import { callAll, isNil } from "helper/utils";

export interface InputNumberProps extends React.ComponentPropsWithoutRef<"input"> {
  status?: FieldStatus;
  helpText?: string;
  setFocus?: (isFocus: boolean) => void;
  min?: string | number;
  currency?: string;
  handleOnBlur?: (event?: any) => void;
  handleOnFocus?: (event?: any) => void;
  label?: string;
  onChangeValue?: (value: number) => void;
}

export const InputMoney = React.forwardRef<HTMLInputElement, InputNumberProps>(function InputMoney(
  {
    currency = "€",
    helpText,
    status = "primary",
    onChange,
    min,
    handleOnBlur,
    handleOnFocus,
    label,
    onChangeValue,
    ...inputProps
  },
  forwardedRef,
) {
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [value, setValue] = React.useState(inputProps.defaultValue);
  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };
  React.useEffect(() => {
    if (!isNil(value)) {
      setIsFocused(true);
    }
  }, [value]);
  const handleKeyPress = (keyPress: React.KeyboardEvent<HTMLInputElement>) => {
    if (keyPress.charCode === 13) {
      toggleEditing();
    }
  };
  const handleBlur = (event: any) => {
    toggleEditing();
    if (isNil(value)) {
      setIsFocused(false);
    }
    handleOnBlur && handleOnBlur(event);
  };
  const handleFocus = (event: any) => {
    toggleEditing();
    setIsFocused(true);
    handleOnFocus && handleOnFocus(event);
  };
  const getLabelClass = clsx(
    isFocused ? "form-label-focus" : "form-label-nonfocus",
    inputProps.readOnly && "text-disabled",
    textStatus[status],
  );

  const handleChangeValue = (input: string) => {
    onChangeValue && onChangeValue(Number(input));
    setValue(input);
  };

  return (
    <div className="relative">
      {label && (
        <Label htmlFor={inputProps.name || ""} className={getLabelClass}>
          {label}
        </Label>
      )}
      <div className="absolute inset-y-0 right-0 inline-flex items-center pr-3">{currency}</div>
      {isEditing ? (
        <TextInput
          {...inputProps}
          className="border-solid form-input focus-visible:outline-none focus: outline-none pr-11"
          type="number"
          onChange={callAll(onChange, (ev) => handleChangeValue(ev.target.value))}
          onBlur={handleBlur}
          onFocus={handleOnFocus}
          onKeyPress={handleKeyPress}
          min={min}
          ref={forwardedRef}
          status={status}
        />
      ) : (
        <TextInput
          {...inputProps}
          className="border-solid form-input focus-visible:outline-none focus: outline-none"
          onFocus={handleFocus}
          onBlur={handleOnBlur}
          ref={forwardedRef}
          status={status}
        />
      )}
      {helpText && <HintText>{helpText}</HintText>}
    </div>
  );
});
